<template>
  <div id="men-setting-id">
    <CardHeader ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="content">
        <router-link
          :to="item.routeName"
          v-for="(item, index) in tabOptions"
          :index="index"
          :key="item.id"
          :id="item.id"
          class="
            d-flex
            justify-content-between
            align-items-center
            content__item
          "
        >
          <div class="d-flex align-items-center">
            <span class="content__item__label f-w3">{{ item.name }}</span>
          </div>
          <img src="~@/assets/image/icon_help/icon-arrow.svg" alt="" />
        </router-link>
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import CardFooter from "@/components/CardFooter";
import "../../assets/scss/men/setting.scss";
export default {
  name: "Setting",
  components: {
    CardHeader,
    CardFooter
  },
  data() {
    return {
      headerTitle: {
        text: "設定",
        isShowTitle: true,
        isShowLogo: false,
        back: null,
        backTo: "/my-page"
      },
      tabOptions: [
        {
          id: "men-acc-setting",
          name: "アカウント",
          routeName: "setting-account"
        },
        {
          id: "men-setting-notification",
          name: "通知・表示",
          routeName: "setting-notification"
        }
      ]
    };
  }
};
</script>

<style scoped></style>
